export const expenseGroup = [
  { text: "Barang", value: 0 },
  { text: "Jasa", value: 1 },
];

export const paymentMethodGroup = [
  { text: "Uang Tunai", value: 0 },
  { text: "Bank Transfer", value: 1 },
  { text: "Fintech", value: 2 },
  { text: "Bon", value: 3 },
];

export const activeInactive = [
  { text: "Aktif", value: true },
  { text: "Non Aktif", value: false },
];

export const yesNo = [
  { text: "Ya", value: true },
  { text: "Tidak", value: false },
];

export const available = [
  { text: "Tersedia", value: true },
  { text: "Tidak Tersedia", value: false },
];

export const membershipInterval = [
  { text: "Harian", value: 0, selected: true },
  { text: "Bulanan", value: 1, selected: false },
  { text: "Tahunan", value: 2, selected: false },
];

import { getYear } from "@/core/utils";
let year = [];
for (let i = getYear(); i > 1900; i--) {
  year.push({ text: i, value: i });
}
export const years = year;
