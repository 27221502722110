<template>
  <CardTable
    :title="title"
    :subTitle="subTitle"
    :columns="table.columns"
    :rows="table.rows"
    :isLoading="table.isLoading"
    :pageSize="serverParams.pageSize"
    :pageNumber="serverParams.pageNumber"
    :totalPage="table.totalPage"
    :totalRecords="table.totalRecords"
    @onRowSelected="onRowSelected"
    @onPerPageChange="onPerPageChange"
    @onPageChange="onPageChange"
    @onSearch="onSearch"
  >
    <template #search>
      <div class="mr-3">
        <b-input
          v-model="serverParams.transactionDate"
          placeholder="Tanggal transaksi"
          type="date"
          @input="onFilter"
        />
      </div>
    </template>
    <template #toolbar>
      <b-button variant="primary" :to="{ name: formName }">
        Buat Pengeluaran
      </b-button>
    </template>
    <template #filter>
      <b-row class="p-3">
        <b-col xl="3">
          <b-input
            v-model="serverParams.transactionNumber"
            placeholder="Nomor Transaksi"
            @input="onFilter"
          ></b-input>
        </b-col>
        <b-col xl="3">
          <b-input
            v-model="serverParams.expense"
            placeholder="Peruntukan"
            @input="onFilter"
          />
        </b-col>
        <b-col xl="3">
          <treeselect
            class="mb-2"
            placeholder="Pilih Kategori"
            v-model="serverParams.expenseGroup"
            :options="options.expenseGroup"
            :normalizer="normalizer"
            @input="onFilter"
          />
        </b-col>
        <b-col xl="3">
          <treeselect
            class="mb-2"
            placeholder="Pilih Kasir"
            v-model="serverParams.cashierId"
            :options="options.cashier"
            @input="onFilter"
          />
        </b-col>
      </b-row>
    </template>
    <template #empty>
      <EmptyTable
        title="Belum ada pengeluaran"
        :description="subTitle"
        button-label="Buat Pengeluaran"
        :href="{ name: formName }"
      />
    </template>
    <template #cell(transactionDate)="data">
      {{ dateFormat(data.value) }}
    </template>
    <template #cell(quantity)="data">
      {{ numberFormat(data.value) }}
    </template>
    <template #cell(amount)="data">
      {{ numberFormat(data.value) }}
    </template>
    <template #cell(grandTotal)="data">
      {{ numberFormat(data.value) }}
    </template>
  </CardTable>
</template>

<script>
import { mapGetters } from "vuex";
import { pengeluaran as columns } from "@/core/datasource/column";
import { expenseGroup } from "@/core/datasource/options";
import {
  pageSize,
  dateFormat,
  numberFormat,
  normalizer,
  getDate,
  getCashier,
} from "@/core/utils";

export default {
  data: () => ({
    formName: "pengeluaranCreate",
    title: "Pengeluaran",
    subTitle: "Daftar pengeluaran bisa direkap disini",
    serverParams: {
      pageNumber: 1,
      pageSize,
      transactionNumber: null,
      transactionDate: getDate(),
      expense: null,
      expenseGroup: null,
      minimumAmount: null,
      maximumAmount: null,
      orderBy: "",
    },
    table: {
      isLoading: false,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
    options: {
      expenseGroup,
      cashier: [],
    },
  }),
  computed: {
    ...mapGetters(["store"]),
  },
  created() {
    const self = this;

    getCashier(self.store.id).then((response) => {
      self.options.cashier = response.data.map((x) => ({
        id: x.id,
        label: x.fullName,
      }));
    });
    self.getAll();
  },
  methods: {
    dateFormat,
    numberFormat,
    normalizer,
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.store.id}/expense`,
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
        })
        .finally(() => (self.table.isLoading = false));
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onRowSelected(items) {
      const self = this;
      self.$router.push({
        name: "pengeluaranUpdate",
        params: {
          id: items[0].id,
        },
      });
    },
    onPerPageChange(params) {
      const self = this;
      self.updateParams({ pageSize: params, pageNumber: 1 });
      self.getAll();
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getAll();
    },
    onSearch(params) {
      const self = this;
      self.updateParams({
        productName: params,
      });
      self.getAll();
    },
    onFilter() {
      const self = this;
      self.getAll();
    },
  },
};
</script>
